import './Leads.css';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import api from '../../../api/axiosInstance';
import { getToken } from '../../../utils/secureStorage';
import { useModal } from '../../../contexts/ModalContext';
const roles = getToken('roles');

const Moderate = () => {
  const navigate = useNavigate();
  const [setBreadcrumb, setBreadcrumbLink] = useOutletContext();
  const [leads, setLeads] = useState([]);
  const [pageStart, setPageStart] = useState(1);
  const [pageEnd, setPageEnd] = useState(10);
  const [Total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [deletePopup, setDeletePopup] = useState(false);
  const [popupId, setPopupId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const { openModal } = useModal();

  useEffect(() => {
    setBreadcrumb(['Dashboard', 'Leads']);
    setBreadcrumbLink(['/dashboard']);
  }, [setBreadcrumb, setBreadcrumbLink]);

  const fetchLeads = async (page = 1, limit = 10) => {
    setLoading(true);
    try {
      const response = await api.get(`/lead/?page=${page}&limit=${limit}`);
      let pageData = response.data.pagination;

      setLeads(response.data.data);
      setTotal(pageData.total);
      setPageStart(pageData.page * pageData.limit - pageData.limit + 1);
      setPageEnd(Math.min(pageData.page * pageData.limit, pageData.total));
    } catch (error) {
      if (error.message === 'Network Error') {
        openModal('failure', 'No Internet Connection');
      } else {
        openModal('failure', error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleNextPage = () => {
    if (pageEnd < Total) {
      const newPage = currentPage + 1;
      setCurrentPage(newPage);
      fetchLeads(newPage, limit);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      setCurrentPage(newPage);
      fetchLeads(newPage, limit);
    }
  };

  const handleLimitChange = (e) => {
    const newLimit = parseInt(e.target.value, 10);
    setLimit(newLimit);
    setCurrentPage(1);
    fetchLeads(1, newLimit);
  };

  useEffect(() => {
    fetchLeads(currentPage, limit);
  }, [currentPage, limit]);

  const deleteLead = async (id) => {
    try {
      let res = await api.delete(`/lead/${id}`);
      if (!res.data.error) {
        openModal('success', res.data.message);
        fetchLeads(currentPage, limit);
      }
    } catch (error) {
      console.error('Error deleting lead:', error);
    }
  };

  if (loading && leads.length === 0) {
    return (
      <div className="flex items-center justify-center w-full h-[90vh]">
        <div role="status">
          <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <>
      {/* Delete Modal */}
      <div id="delete-modal" tabIndex="-1" className={`${deletePopup ? '' : 'hidden'} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 bg-[#878b9385] justify-center flex items-center w-full md:inset-0 h-full max-h-full`}>
        <div className="relative p-4 w-full max-w-md max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="p-4 md:p-5 text-center">
              <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
              </svg>
              <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Delete Lead with ID {popupId}</h3>
              <button onClick={() => { deleteLead(popupId); setDeletePopup(false); }} type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5">
                Delete
              </button>
              <button onClick={() => setDeletePopup(false)} type="button" className="py-2.5 px-5 ml-3 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600">
                No, cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="p-4 bg-white sm:p-6 dark:bg-gray-800">
        <div className="grid grid-cols-2">
          <div className="col align-bottom">
            <h3 className="text-xl pt-3 font-bold text-gray-900 dark:text-white">Leads</h3>
          </div>
          <div className="col text-sm mt-3 mb-2 w-full text-right font-normal text-gray-500 dark:text-gray-400">
            <Link to={`/dashboard/Lead/create`}>
              <button type="button" className="py-2 px-5 mb-2 me-2 text-xs font-medium text-gray-900 bg-white rounded-full border border-gray-200 hover:bg-gray-100">
                Create Lead
              </button>
            </Link>
            <Link to={`/dashboard/Lead/bulkupload`}>
              <button type="button" className="py-2 px-5 mb-2 text-xs font-medium text-gray-900 bg-white rounded-full border border-gray-200 hover:bg-gray-100">
                Bulk Upload
              </button>
            </Link>
          </div>
        </div>

        {/* Pagination and Limit Selection */}
        <div className="flex items-center justify-between mb-4">
          <div className="flex">
            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className={`flex items-center justify-center py-2 px-5 text-xs font-medium text-gray-900 bg-white border border-gray-200 hover:bg-gray-100 rounded-s-lg ${currentPage === 1 ? 'opacity-70 cursor-not-allowed' : ''}`}
            >
              <svg className="w-3.5 h-3.5 me-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 5H1m0 0 4 4M1 5l4-4" />
              </svg>
              Prev
            </button>
            <button
              onClick={handleNextPage}
              disabled={pageEnd >= Total}
              className={`flex items-center justify-center py-2 px-5 text-xs font-medium text-gray-900 bg-white border border-gray-200 hover:bg-gray-100 rounded-e-lg ${pageEnd >= Total ? 'opacity-70 cursor-not-allowed' : ''}`}
            >
              Next
              <svg className="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
              </svg>
            </button>
          </div>
          <div className="text-sm text-gray-500 dark:text-gray-400">
            Show <span className="font-semibold">{pageStart}-{pageEnd}</span> of <span className="font-semibold">{Total}</span>
          </div>
          <div>
            <select value={limit} onChange={handleLimitChange} className="py-2 px-3 border text-sm rounded-lg border-gray-300 rounded">
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
        </div>

        <div className="flex flex-col">
          <div className="overflow-x-auto shadow rounded-lg">
            <div className="inline-block min-w-full">
              <div className="overflow-hidden sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
                  <thead className="bg-gray-50 dark:bg-gray-700">
                    <tr>
                      <th className="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-white"># ID</th>
                      <th className="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-white">Name</th>
                      <th className="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-white">Tags</th>
                      <th className="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-white">Status</th>
                      <th className="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-white">Action</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white dark:bg-gray-800">
                    {loading ? (
                      <tr>
                        <td colSpan="5" className="p-4 text-center">
                          <div role="status">
                            <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600 inline-block" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                            </svg>
                            <span className="sr-only">Loading...</span>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      leads.length > 0 ? (
                        leads.map((lead) => (
                          <tr key={lead._id}>
                            <td className="p-4 text-sm font-normal text-gray-900 dark:text-white">{lead._id}</td>
                            <td className="p-4 text-sm font-normal whitespace-nowrap">{lead.name}</td>
                            <td className="p-4 text-sm font-normal whitespace-nowrap">
                              {lead.tags && lead.tags.length > 0 ? (
                                lead.tags.map((tag, idx) => (
                                  <span key={idx} className="inline-block bg-blue-100 text-blue-800 text-xs font-medium mr-1 px-2 py-0.5 rounded">
                                    {tag}
                                  </span>
                                ))
                              ) : (
                                '-'
                              )}
                            </td>
                            <td className="p-4 text-sm whitespace-nowrap">
                              <span className="rounded-full capitalize bg-orange-100 text-orange-800 text-xs font-medium mr-2 px-2.5 py-0.5 dark:bg-gray-700 dark:text-orange-400 border dark:border-orange-500">
                                {lead.status}
                              </span>
                            </td>
                            <td className="p-4 text-sm font-normal text-gray-900 dark:text-white">
                              <Link to={`/dashboard/Lead/${lead._id}`}>
                                <button type="button" className="py-2 px-5 mb-2 me-2 text-xs font-medium text-gray-900 bg-white rounded-full border border-gray-200 hover:bg-gray-100">
                                  Edit Lead
                                </button>
                              </Link>
                              <button onClick={() => { setDeletePopup(true); setPopupId(lead._id); }} type="button" className="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2 mb-2">
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5" className="p-4 text-sm font-normal text-gray-900 dark:text-white">No Leads Yet</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-end pt-3 sm:pt-6">
          {roles.includes("admin") && (
            <div className="flex-shrink-0">
              <Link className="inline-flex items-center p-2 text-xs font-medium uppercase rounded-lg text-primary-700 hover:bg-gray-100 dark:text-primary-500 dark:hover:bg-gray-700">
                Approval Report
                <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
                </svg>
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Moderate;
