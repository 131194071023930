import React, { useState, useEffect } from 'react';
import { useNavigate, useOutletContext } from "react-router-dom";
import api from '../../api/axiosInstance';
import { useModal } from '../../contexts/ModalContext';

function convertUTCToLocal(utcDateTime) {
  const date = new Date(utcDateTime);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

const Post = () => {
  const localDate = new Date();
  const navigate = useNavigate();
  const [setBreadcrumb, setBreadcrumbLink] = useOutletContext();
  const { openModal } = useModal();

  // State fixes: automationName is a string, availableTags is an array
  const [proceeding, setProceeding] = useState(false);
  const [proceedingRefresh, setProceedingRefresh] = useState(false);
  const [leadCount, setLeadCount] = useState(0);
  const [automationName, setAutomationName] = useState(''); // string
  const [templates, setTemplates] = useState(null);
  const [templateId, setTemplateId] = useState(null);
  const [lastUpdateDate, setLastUpdateDate] = useState(convertUTCToLocal(localDate));
  const [error, setError] = useState('');

  // New states for date range and tags
  const [createdFrom, setCreatedFrom] = useState('');
  const [createdTo, setCreatedTo] = useState('');
  const [availableTags, setAvailableTags] = useState([]); // initialize as empty array
  const [selectedTags, setSelectedTags] = useState([]);
  const [includeNoTags, setIncludeNoTags] = useState(true);

  useEffect(() => {
    setBreadcrumb(['Dashboard', 'Automations', 'Create']);
    setBreadcrumbLink(['/dashboard', '/dashboard/Automations']);
  }, [setBreadcrumb, setBreadcrumbLink]);

  // Fetch lead count using a date range
  const fetchLeadCount = async () => {
    if (!createdFrom || !createdTo) {
      openModal('failure', 'Please select both From and To dates.');
      return;
    }
    const fromISO = convertUTCToLocal(createdFrom);
    const toISO = convertUTCToLocal(createdTo);

    const conditions = [
      {
        field: "createdAt",
        operator: "greaterThan",
        value: fromISO
      },
      {
        field: "createdAt",
        operator: "lessThan",
        value: toISO
      }
    ];

    if (selectedTags.length > 0) {
      conditions.push({
        field: "tags",
        operator: "in",
        value: selectedTags
      });
    }

    try {
      setProceedingRefresh(true);
      const response = await api.post(`/automation/automated-cond-lead-count`, {
        matchType: "all",
        conditions,
        includeNoTags
      });
      if (!response.data.error) {
        setLeadCount(response.data.noOfLeads);
      } else {
        setError(response.data.message);
      }
      setProceedingRefresh(false);
    } catch (err) {
      if (err.message === 'Network Error') {
        openModal('failure', 'No Internet Connection');
      } else {
        openModal('failure', err.message);
      }
      setProceedingRefresh(false);
    }
  };

  const fetchTemplates = async () => {
    try {
      const response = await api.get(`/templates/list`);
      if (!response.data.error) {
        setTemplates(response.data.templates);
        setTemplateId(response.data.templates[0]._id);
      } else {
        openModal('failure', response.data.message);
      }
    } catch (err) {
      if (err.message === 'Network Error') {
        setError('No Internet Connection');
      } else {
        openModal('failure', err.message);
      }
    }
  };

  // New: Fetch available tags from the backend
  const fetchAvailableTags = async () => {
    try {
      const response = await api.get(`/automation/tags`);
      if (!response.data.error) {
        setAvailableTags(response.data.tags);
      } else {
        openModal('failure', response.data.message);
      }
    } catch (err) {
      if (err.message === 'Network Error') {
        openModal('failure', 'No Internet Connection');
      } else {
        openModal('failure', err.message);
      }
    }
  };

  useEffect(() => {
    fetchTemplates();
    fetchAvailableTags();
  }, []);

  const handleCreateAutomation = async (event) => {
    event.preventDefault();
    if (leadCount === 0) {
      openModal('failure', 'Number of leads cannot be zero.');
      return;
    }
    if (!createdFrom || !createdTo) {
      openModal('failure', 'Please select both From and To dates.');
      return;
    }
    setProceeding(true);
    const fromISO = convertUTCToLocal(createdFrom);
    const toISO = convertUTCToLocal(createdTo);
    // Build conditions array with date range and optional tag filter
    let conditions = [
      {
        field: "createdAt",
        operator: "greaterThan",
        value: fromISO
      },
      {
        field: "createdAt",
        operator: "lessThan",
        value: toISO
      }
    ];
    if (selectedTags.length > 0) {
      conditions.push({
        field: "tags",
        operator: "in",
        value: selectedTags
      });
    }
    try {
      const response = await api.post(`/automation/automated-cond-emails`, {
        name: automationName,
        matchType: "all",
        conditions,
        templateId,
        includeNoTags
      });
      if (!response.data.error) {
        openModal('success', response.data.message);
        navigate('/dashboard/Automations');
      } else {
        openModal('failure', response.data.message);
      }
    } catch (err) {
      if (err.message === 'Network Error') {
        setError("No Internet Connection.");
      } else {
        openModal('failure', err.message);
      }
    }
    setProceeding(false);
  };

  if (!templates) return (
    <div className="flex items-center justify-center w-full h-[90vh]">
      <div role="status">
        <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
        </svg>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );

  return (
    <div className="p-4 bg-white sm:p-6 dark:bg-gray-800">
      {/* Filter Conditions Table */}
      <div className="flex flex-col mb-4">
        <div className="overflow-x-auto shadow rounded-lg">
          <div className="inline-block min-w-full">
            <div className="overflow-hidden sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
                <thead className="bg-gray-50 dark:bg-gray-700">
                  <tr>
                    <th className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">Filter Field</th>
                    <th className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">Value</th>
                  </tr>
                </thead>
                <tbody className="bg-white dark:bg-gray-800">
                  <tr>
                    <td className="p-4 text-sm font-normal text-gray-900 dark:text-white">Lead From (IST)</td>
                    <td className="p-4">
                      <input
                        type="datetime-local"
                        value={createdFrom}
                        onChange={(e) => setCreatedFrom(e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="p-4 text-sm font-normal text-gray-900 dark:text-white">Lead To (IST)</td>
                    <td className="p-4">
                      <input
                        type="datetime-local"
                        value={createdTo}
                        onChange={(e) => setCreatedTo(e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="p-4 text-sm font-normal text-gray-900 dark:text-white">Filter by Tags</td>
                    <td className="p-4">
                      <select
                        multiple
                        value={selectedTags}
                        onChange={(e) => {
                          const options = e.target.options;
                          let values = [];
                          for (let i = 0; i < options.length; i++) {
                            if (options[i].selected) {
                              values.push(options[i].value);
                            }
                          }
                          setSelectedTags(values);
                        }}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                      >
                        {availableTags.map((tag) => (
                          <option key={tag} value={tag}>{tag}</option>
                        ))}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td className="p-4 text-sm font-normal text-gray-900 dark:text-white"> </td>
                    <td className="p-4 flex items-center">
                      <label className="flex items-center mt-2">
                        <input
                          type="checkbox"
                          checked={includeNoTags}
                          onChange={() => setIncludeNoTags(!includeNoTags)}
                          className="mr-2"
                        />
                        Include leads without tags
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td className="p-4 text-sm font-normal text-gray-900 dark:text-white">Target Leads</td>
                    <td className="p-4 flex items-center">
                      {leadCount}
                      <button onClick={fetchLeadCount} type="button" className="ml-3 px-3 py-2 text-xs font-medium text-gray-900 bg-white rounded-full border border-gray-200 hover:bg-gray-100">
                        {proceedingRefresh ? 'Refreshing...' : 'Refresh'}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* Automation Creation Form */}
      <form onSubmit={handleCreateAutomation}>
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <label htmlFor="automationName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Automation Name</label>
            <input
              type="text"
              id="automationName"
              onChange={(e) => setAutomationName(e.target.value)}
              value={automationName}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
              required
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <label htmlFor="template" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select Template</label>
            <select
              onChange={(e) => setTemplateId(e.target.value)}
              value={templateId}
              id="template"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5"
              required
            >
              {templates.map((template) => (
                <option key={template._id} value={template._id}>{template.templateName}</option>
              ))}
            </select>
          </div>
          <div className="col-span-6">
            <button
              className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              type="submit"
            >
              {proceeding ? (
                <div className="inline mr-3" role="status">
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 inline text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              ) : 'Create Automation'}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Post;
